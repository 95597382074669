import { createApp } from 'vue'
import numeral from "numeral";
import hljs from 'highlight.js'
import "highlight.js/scss/atom-one-dark.scss";
import "nprogress/nprogress.css";
import * as Icons from '@ant-design/icons-vue'
import 'viewerjs/dist/viewer.css';
import { api as viewerApi } from "v-viewer"
import App from './App.vue';
import router from './router';
import * as http from './utils/http';
import * as echarts from 'echarts'
const app = createApp(App)
import {
  Image,
  Cascader,
  Form,
  Avatar,
  Breadcrumb,
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Drawer,
  Dropdown,
  Input,
  InputNumber,
  Layout,
  Menu,
  message,
  Popconfirm,
  Radio,
  Result,
  Row,
  Select,
  Skeleton,
  Collapse,
  Space,
  Switch,
  Table,
  Tabs,
  Tag,
  Tree,
  TreeSelect,
  List,
  Tooltip,
  Comment,
  Popover,
  Transfer,
  Modal,
  Upload, Checkbox, Steps, Card, Statistic, Empty, Rate, Pagination
} from 'ant-design-vue';
import "./main.scss";

app.config.productionTip = false;
app.use(Image);
app.use(Statistic);
app.use(Card);
app.use(Cascader);
app.use(Form);
app.use(Button);
app.use(Drawer);
app.use(ConfigProvider);
app.use(Layout);
app.use(Dropdown);
app.use(Space);
app.use(List)
app.use(Menu);
app.use(Comment)
app.use(Tooltip);
app.use(Breadcrumb);
app.use(Avatar);
app.use(Collapse)
app.use(Input);
app.use(Table);
app.use(InputNumber);
app.use(DatePicker);
app.use(Select);
app.use(Divider);
app.use(Skeleton);
app.use(Popconfirm);
app.use(Tag);
app.use(Result);
app.use(Tabs);
app.use(Tree);
app.use(Popover)
app.use(Row);
app.use(Col);
app.use(Radio);
app.use(Switch);
app.use(Upload);
app.use(Modal)
app.use(Transfer)
app.use(TreeSelect);
app.use(Checkbox);
app.use(Steps);
app.use(Empty);
app.use(Rate);
app.use(Pagination);

/* prototype */
app.config.globalProperties.$message = message;
app.config.globalProperties.$http = http;
app.config.globalProperties.$echarts = echarts;
app.config.globalProperties.$viewerApi = viewerApi;

/* directive */
if (process.env.NODE_ENV === 'development') {
  app.directive('highlightjs', {
    deep: true,
    bind: function (el, binding) {
      const targets = el.querySelectorAll('code')
      targets.forEach((target) => {
        if (binding.value) {
          target.innerHTML = binding.value
        }
        hljs.highlightBlock(target)
      })
    },
    componentUpdated: function (el, binding) {
      const targets = el.querySelectorAll('code')
      targets.forEach((target) => {
        if (binding.value) {
          target.innerHTML = binding.value
          hljs.highlightBlock(target)
        }
      });
    }
  });
}

// 注册组件
Object.keys(Icons).forEach(key => {
  app.component(key, Icons[key])
})
// 添加到全局
app.config.globalProperties.$icons = Icons

app.use(router)
app.mount('#app')

app.config.globalProperties.$formatPercent = (value) => {
  return (value * 100).toFixed(2) + '%';
}
app.config.globalProperties.$formatCurrency = (value) => {
  return numeral(value).format('0,0.00') + "元";
}

app.config.globalProperties.$formatCurrencyNumber = (value) => {
  return numeral(value).format('0,0.00');
}

app.config.globalProperties.$isMobile = document.documentElement.clientWidth <= 768;