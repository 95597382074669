<template>
  <div class="inline">
    <span @click="openDrawer" v-if="disabledValue !== 'false'">
      <a-button v-if="type === 'button' && !action.isHidden" :type="action.config.color" :disabled="disabledValue">
        <template #icon>
          <component v-if="action.icon" :is="$icons[action.icon]"></component>
        </template>
        {{ action.text }}</a-button>
      <a-button v-else-if="type === 'link'" style="padding: 0;"
        :class="{ 'text-danger': action.config.color === 'danger' }" :disabled="!disabledValue" type="link">
        <component v-if="action.icon" :is="$icons[action.icon]"></component>
        {{ action.text }}
      </a-button>
    </span>

    <a-drawer :bodyStyle="{ padding: $isMobile ? '8px' : '' }" :title="action.config.title" placement="right"
      :closable="true" :destroy-on-close="true" :width="$isMobile ? 320 : 800" :visible="drawerVisible"
      @close="closeDrawer">
      <div class="fa-info">{{ action.config.info ? action.config.info(selectedRows) : '' }}</div>
      <a-skeleton v-if="initLoading" active :title="false" :paragraph="{ rows: 4 }" />
      <a-form v-else-if="initDataReady" ref="form" :model="form" :rules="rules" layout="vertical"
        :class="[$isMobile ? 'm-formCon' : 'formCon']">
        <a-form-item
          :class="control.type === 'textarea' || control.type === 'steps' || control.type === 'editor' || control.type === 'table' ? 'textareaCon' : ''"
          v-for="control in controls" :key="control.key" :name="control.key">
          <div class="label-style" slot="label">
            <div>
              <span v-if="control.required" class="ls-required">*</span>
              <span>{{ control.label }}</span>
            </div>
            <a-popover v-if="control.tips" placement="top">
              <template #content>
                <p class="ls-content">{{ control.tips }}</p>
              </template>
              <question-circle-outlined class="ls-icon" />
            </a-popover>
          </div>
          <!-- table -->
          <template v-if="control.type === 'table'">
            <a-table size="middle" :scroll="{ y: control.scrollHeight }" :pagination="false" bordered
              :dataSource="initData[control.config.options]" :columns="control.config.columns">
              <template #bodyCell="{ column, record }">
                <template v-if="column.type === 'datetime'">
                  <span>{{ getYMDHMSMoment(getArrSpliComma(column.key, record)) }}</span>
                </template>
                <template v-else>
                  <span>{{ getArrSpliComma(column.key, record) }}</span>
                </template>
              </template>

            </a-table>

          </template>
          <!-- 文本 -->
          <template v-if="control.type === 'text'">
            <a-input @blur="control.blur ? blurChange($event, control.blurconfig) : ''"
              v-model:value.trim="form[control.key]" :placeholder="getPlaceholder(control)" :disabled="control.disabled"
              allow-clear />
          </template>

          <!-- 可空格文本 -->
          <template v-if="control.type === 'spaceText'">
            <a-input @blur="control.blur ? blurChange($event, control.blurconfig) : ''"
              v-model:value="form[control.key]" :placeholder="getPlaceholder(control)" :disabled="control.disabled"
              allow-clear />
          </template>

          <!-- 数字 -->
          <template v-if="control.type === 'number'">
            <a-input @blur="control.blur ? blurChange($event, control.blurconfig) : ''"
              v-model:value.trim="form[control.key]" type="number" :placeholder="getPlaceholder(control)"
              :disabled="control.disabled" allow-clear />
          </template>

          <!-- 日期 -->
          <template v-else-if="control.type === 'date'">
            <a-date-picker v-model:value="form[control.key]" :placeholder="getPlaceholder(control)"
              :disabled-date="control.disabledDate ? disabledDate : () => { }" style="width: 100%" :getPopupContainer="triggerNode => {
      return triggerNode.parentNode || document.body;
    }" />
          </template>

          <!-- 日期时间 -->
          <template v-else-if="control.type === 'datetime'">
            <a-date-picker show-time v-model:value="form[control.key]" :placeholder="getPlaceholder(control)"
              :disabled-date="control.disabledDate ? disabledDate : () => { }" :getPopupContainer="triggerNode => {
      return triggerNode.parentNode || document.body;
    }" style="width: 100%" />
          </template>

          <!-- 文本域 -->
          <template v-else-if="control.type === 'textarea'">
            <a-textarea v-model:value="form[control.key]" :placeholder="getPlaceholder(control)"
              style="width: 100%;height: 100px;" />
          </template>

          <!-- 单选框 -->
          <template v-else-if="control.type === 'radio'">
            <a-radio-group v-model:value="form[control.key]" @change="radioChange" :disabled="control.config.disabled">
              <a-radio v-for="option in control.config.options" :key="option.value" :value="option.value">
                {{ option.text }}
              </a-radio>
            </a-radio-group>
          </template>

          <!-- 评分 -->
          <template v-else-if="control.type === 'rate'">
            <div>
              <a-rate v-model:value="form[control.key]" :allow-half="control.allowHalf" />
            </div>
          </template>

          <!-- 数值 -->
          <template v-else-if="control.type === 'numeric'">

            <!-- 金额 -->
            <template v-if="control.config.format === 'currency'">
              <a-input-number
                :formatter="value => (value == null || value === '') ? value : `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/¥\s?|(,*)/g, '')" v-model:value="form[control.key]"
                :disabled="control.disabled" :min="control.config.min"
                :max="control.config.max && control.config.max(form)"
                :placeholder="getPlaceholder(control)" style="width: 100%" />
            </template>

            <!-- 自定义单位 -->
            <template v-else-if="control.config.format === 'unit'">
              <a-input-number style="width: 100%;" v-model:value="form[control.key]"
                :formatter="value => (value == null || value === '') ? value : `${value}`.replace(/(\.\d{2})\d*$/, '$1')">
                <template #addonAfter>{{ control.config.text }}</template>
              </a-input-number>
            </template>

            <!-- 百分比 -->
            <template v-else-if="control.config.format === 'percent'">
              <a-input-number :min="0" :max="100"
                :formatter="value => (value == null || value === '') ? value : `${value}%`"
                :parser="value => value.replace('%', '')" v-model:value="form[control.key]"
                :placeholder="getPlaceholder(control)" style="width: 100%" />
            </template>
            <!-- 普通 单位万 -->
            <template v-else-if="control.config.format === 'money'">
              <a-input-number style="width: 100%" v-model:value="form[control.key]"
                :formatter="value => (value == null || value === '') ? value : `¥ ${value}`.replace(/(\.\d{2})\d*$/, '$1')">
                <template #addonAfter>{{ control.config.suffix ? control.config.suffix : '万' }}</template>
              </a-input-number>
            </template>
            <!-- +-排序 -->
            <template v-else-if="control.config.format === 'sort'">
              <a-input-number class="sortInputNumber" controls style="width: 100%;" v-model:value="form[control.key]">
                <template #addonBefore>
                  <span
                    @click="aInputNumberStep(form[control.key] + 1, { offset: 1, type: 'up' }, control.key)">+</span>
                </template>
                <template #addonAfter>
                  <span
                    @click="aInputNumberStep(form[control.key] - 1, { offset: 1, type: 'down' }, control.key)">-</span>
                </template>
              </a-input-number>
            </template>

            <!-- 普通 -->
            <a-input-number v-else :min="control.config.min && control.config.min(form)"
              :max="control.config.max && control.config.max(form)" v-model:value="form[control.key]"
              :placeholder="getPlaceholder(control)" style="width: 100%"
              @blur="handleNumChange($event, control.config.max(form), control.config.min(form))" />
          </template>

          <!-- 下拉选择 -->
          <template v-else-if="control.type === 'select' && !control.config.disabled">
            <a-select
              :disabled="control.isDisabled ? control.isDisabled(selectedRows, form[control.key]) : false || control.disabled"
              :style="{ width: control.config.width + 'px' }" v-model:value="form[control.key]"
              :placeholder="getPlaceholder(control)"
              @change="value => cascadeSelect(value, control.isCascade, control.cascadeData)" allow-clear
              style="width: 100%">
              <a-select-option v-for="option in control.config.options" :key="option.value"
                :disabled="option.disabled">{{ option.text }}
              </a-select-option>
            </a-select>
          </template>
          <!-- 下拉多选选择 -->
          <template v-else-if="control.type === 'mSelect'">
            <a-select :style="{ width: control.config.width + 'px' }" v-model:value="form[control.key]"
              :placeholder="getPlaceholder(control)" mode="multiple" allow-clear style="width: 100%" show-search
              :filter-option="filterOption" @change="getSelect($event, control)">
              <a-select-option v-for="option in control.config.options" :key="option.id" :name="option.name"
                :disabled="option.disabled">{{ option.name }}
              </a-select-option>
            </a-select>
          </template>

          <!-- 下拉搜索选择 -->
          <template v-else-if="control.type === 'souSelect'">
            <a-select class="page-btm" :filter-option="filterSouOption" show-search
              :style="{ width: control.width + 'px' }" v-model:value="form[control.key]" placeholder="全部" allow-clear
              @change="getSouSelect($event, control, control.config.options)">
              <a-select-option v-for="option in control.config.options" :key="option.id" :text="option.name">
                {{ option.name }}
              </a-select-option>
            </a-select>
          </template>

          <!-- switch -->
          <template v-else-if="control.type === 'switch'">
            <a-switch v-model:checked="form[control.key]" default-checked />
          </template>

          <!-- 文件 -->
          <template v-else-if="control.type === 'file' && !control.config.disabled">
            <a-upload :accept="control.config.accept.map(ext => `.${ext}`).join(',')" :action="control.config.uploadUrl"
              :list-type="control.config.format === 'image' ? 'picture-card' : 'text'"
              :file-list="fileUploadMap[control.key].fileList" :before-upload="file => onBeforeUpload(control, file)"
              @preview="handleUploadPreview($event, control)" @change="info => handleUploadChange(control, info)">
              <template v-if="fileUploadMap[control.key].fileList.length < 1">
                <div v-if="control.config.format === 'image'">
                  <plus-outlined />
                  <div class="ant-upload-text">上传</div>
                </div>
                <div v-else-if="control.config.format === 'compress'">
                  <a-button>
                    <upload-outlined />
                    选择压缩文件
                  </a-button>
                </div>
                <div v-else-if="control.config.format === 'video'">
                  <a-button>
                    <upload-outlined />
                    选择视频文件
                  </a-button>
                </div>
              </template>
              <template v-else>
                <div
                  v-if="control.config.format === 'image' && (fileUploadMap[control.key].fileList.length < control.config.length)">
                  <plus-outlined />
                  <div class="ant-upload-text">上传</div>
                </div>
                <div v-else-if="control.config.format === 'compress'">
                  <a-button>
                    <upload-outlined />
                    选择压缩文件
                  </a-button>
                </div>
                <div v-else-if="control.config.format === 'video'">
                  <a-button>
                    <upload-outlined />
                    选择视频文件
                  </a-button>
                </div>
              </template>

            </a-upload>
            <a-modal title="文件预览" v-model:open="fileUploadMap[control.key].previewVisible"
              @cancel="fileUploadMap[control.key].previewVisible = false" :footer="null">
              <template v-if="control.config.format === 'image'">
                <img style="width: 100%" :src="fileUploadMap[control.key].previewUrl" alt="预览图" />
              </template>
              <template v-if="control.config.format === 'video'">
                <video :src="fileUploadMap[control.key].previewUrl" controls style="width: 100%" />
              </template>
            </a-modal>
            <span v-if="control.config.imageSize">推荐上传尺寸:{{ control.config.imageSize }}</span>
          </template>

          <!-- 树状下拉选择 -->
          <template v-else-if="control.type === 'treeSelect'">
            <a-tree-select v-if="control.config.options" v-model:value="form[control.key]" style="width: 100%" allow-clear
              :tree-data="getTreeData(control)" tree-default-expand-all :placeholder="getPlaceholder(control)">
            </a-tree-select>
            <a-tree-select v-if="control.config.list" v-model:value="form[control.key]" style="width: 100%" allow-clear
              :tree-data="control.config.list" tree-default-expand-all :placeholder="getPlaceholder(control)">
            </a-tree-select>
          </template>

          <!-- 日期范围 -->
          <template v-else-if="control.type === 'dateRange'">
            <a-range-picker v-model:value="form[control.key]" style="width: 100%" :show-time="control.config.showTime"
              :placeholder="[getPlaceholder(control), getPlaceholder(control)]" />
          </template>

          <!-- 复选框 -->
          <template v-else-if="control.type === 'checkbox'">
            <a-checkbox-group v-model:value="form[control.key]">
              <template v-for="option in control.config.options">
                <template v-if="!option.disabled || form[control.key].find(v => v === option.value)">
                  <a-checkbox :value="option.value">{{ option.text }}</a-checkbox>
                  <br v-if="control.config.layout === 'vertical'" />
                </template>
              </template>
            </a-checkbox-group>
          </template>

          <!-- 树状 -->
          <template v-else-if="control.type === 'tree'">
            <a-tree :selectable="false" v-model:checkedKeys="form[control.key]" checkable blockNode
              :tree-data="control.config.options">
              <!-- <template #icon>
                <component v-if="icon" :is="$icons[icon]"></component>
              </template> -->
            </a-tree>
          </template>


          <!-- 级联选择 -->
          <template v-else-if="control.type === 'cascader'">
            <a-cascader v-model:value="form[control.key]" :show-search="{ filter }" change-on-select
              :options="control.config.options" placeholder="全部" />
          </template>
          <!-- 步骤条 -->
          <template v-else-if="control.type === 'steps'">
            <a-steps v-model:current="currentStep" type="navigation" size="small" :items="control.config.options"
              @change="getStep(control.key, control.config.options, currentStep)"></a-steps>
          </template>

          <!-- 穿梭框 -->
          <template v-else-if="control.type === 'transfer'">
            <a-transfer v-model:target-keys="form[control.key]" :data-source="control.config.options" :one-way="true"
              :titles="[control.config.source, control.config.target]" :render="item => item.title" />
          </template>

          <!-- 富文本 -->
          <template v-else-if="control.type === 'editor'">
            <div style="width: 100%;">
              <vue3-tinymce style="width: 100%;" v-model="form[control.key]" :setting="setting" script-src="/tinymce/tinymce/tinymce.min.js" />
            </div>
          </template>

          <!-- 数字范围 -->
          <template v-else-if="control.type === 'numberRange'">
            <div style="display: flex; align-items: center;">
              <a-input-number class="page-btm" style="width: 100px" v-model:value="form[control.start]"
                placeholder="最少" />
              <span style="margin: 0 10px">-</span>
              <a-input-number class="page-btm" style="width: 100px" v-model:value="form[control.end]"
                placeholder="最多" />
              <span style="margin-left: 10px;">{{ control.unit }}</span>
            </div>
          </template>
          <!-- 副标题 -->
          <template v-else-if="control.type === 'title'">
            <p style="width: 100%; font-size: 18px; padding: 10px 0; font-weight: bold;">{{ control.text }}</p>
          </template>
          
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="submit" :loading="submitLoading">{{ action.config.submitText }}</a-button>
        </a-form-item>

      </a-form>
    </a-drawer>
    <a-modal v-model:open="openModal" :title="modalTitle" @ok="handleOk">
      <div v-html="modalContent"></div>
    </a-modal>
    <a-modal v-model:open="openVerifyModal" title="警告" @cancel="handleOk" @ok="verifySubmit">
      <div>{{ action.config.secondVerify }}</div>
    </a-modal>
  </div>
</template>

<script>
import { capitalize, formatBytes } from "@/utils/core";
import { get } from "@/utils/http";
import tree from "@/utils/tree";
import config from "@/config";
import { getProfile } from "@/utils/session";
import dayjs from "dayjs";
import Vue3Tinymce from '@jsdawn/vue3-tinymce';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  name: "ConfirmAction",
  props: ['action', 'type', 'selectedRows', 'disabledValue', 'tableData'],
  components: {
    Vue3Tinymce
  },
  data() {
    return {
      //富文本设置
      setting: {
        menubar: false,
        toolbar:
          'bold italic underline h1 h2 blockquote codesample numlist bullist link image | removeformat fullscreen',
        plugins: 'codesample link image table lists fullscreen',
        toolbar_mode: 'sliding',
        nonbreaking_force_tab: true,
        link_title: false,
        default_link_target: '_blank',
        // 自定义 图片上传模式
        custom_images_upload: true,
        images_upload_url: '',
        custom_images_upload_callback: (res) => res.url,
        custom_images_upload_header: { 'X-Token': 'xxxx' },
        // custom_images_upload_param: { id: 'xxxx01', age: 18 },
        // 设置中文语言
        language: 'zh-Hans',
        language_url: '/tinymce/langs/zh-Hans.js',
        content_style: 'body{font-size: 14px}',
      },
      drawerVisible: false,
      submitLoading: false,
      form: this.initForm(),
      initData: {},
      initLoading: false,
      initDataReady: false,
      fileUploadMap: this.initFileUploadMap(),
      current: 0,
      currentStep: 0,
      openModal: false,
      modalTitle: '',
      modalContent: '',
      transferData: [],
      resId: null,
      openVerifyModal: false
    }
  },

  computed: {
    /**
     * 验证规则
     */
    rules() {
      return this.controls.reduce((prev, control) => {
        const controlRules = prev[control.key] = prev[control.key] ?? [];
        if (control.required) {
          controlRules.push({
            required: true,
            message: `请${this.getPlaceholder(control)}`
          });
        }
        if (control.config.rules != null) {
          const configRules = typeof control.config.rules === 'string' ? [control.config.rules] : control.config.rules;
          configRules.forEach(ruleName => {
            if (ruleName === 'phoneNumber') {
              controlRules.push({
                pattern: /^(0065|\+65)?[89]\d{7}$|^((\+|00)86)?1[3456789]\d{9}$/,
                message: '请输入正确的手机号'
              });
            } else if (ruleName === 'email') {
              controlRules.push({
                type: 'email',
                message: '请输入正确的邮箱地址'
              });
            } else if (ruleName === 'wechat') {
              controlRules.push({
                pattern: /^[a-zA-Z-_0-9]([-_a-zA-Z0-9]{5,19})+$/,
                message: '请输入正确的微信号'
              });
            } else if (ruleName === 'url') {
              controlRules.push({
                pattern: /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/,
                message: '请输入以 http:// 或 https:// 开头的正确网址'
              });
              controlRules.push({ max: 250, message: '链接地址不能超过500字符' });
            } else {
              controlRules.push(ruleName);
            }
          });
        }
        return prev;
      }, {});

    },

    /**
     * 表单项
     */

    controls() {
      let controls = this.action.config.controls ?? [];
      let newControls = controls.map(control => {

        if (control.defaultValue) {
          this.form[control.key] = control.defaultValue;
        }
        return {
          ...control,
          config: {
            rules: [],
            ...control.config,
            hidden: this.getHideData(this.initData, control),
            ...(control.type === 'select' ? {
              options: typeof control.config.options === 'string' ? (this.initData[control.config.options] ?? []).map(item => ({
                text: item[control.config.textKey ?? 'name'],
                value: item[control.config.valueKey ?? 'id']
              })) : control.config.options
            } : {}),
            ...(control.type === 'souSelect' ? {
              options: typeof control.config.options === 'string' ? (this.initData[control.config.options] ?? []).map(item => ({
                name: item[control.config.textKey ?? 'name'],
                id: item[control.config.valueKey ?? 'id']
              })) : control.config.options
            } : {}),
            ...(control.type === 'mSelect' ? {
              // options: this.initData[control.config.options],
              options: this.getMSelectData(this.initData, control)
            } : {}),
            ...(control.type === 'steps' ? {
              options: this.getStepsData(this.initData, control)
            } : {}),
            ...(control.type === 'file' ? {
              accept: control.config.accept ?? ({
                image: ['png', 'jpg', 'gif'],
                compress: ['zip', 'rar', '7z']
              }[control.config.format])
            } : {}),
            ...(control.type === 'checkbox' ? {
              options: typeof control.config.options === 'string' ? (this.initData[control.config.options] ?? []).map(item => ({
                text: item[control.config.textKey ?? 'name'],
                value: item[control.config.valueKey ?? 'id'],
                disabled: item['locked']
              })) : control.config.options
            } : {}),
            ...(control.type === 'tree' ? {
              options: typeof control.config.options === 'function' ? control.config.options({ config }) : control.config.options
            } : {}),
            ...(control.type === 'transfer' ? {
              options: [] ? this.transferData : options
            } : {}),
            ...(control.type === 'treeSelect' ? {
              options: typeof control.config.options === 'string'
                ? tree(this.initData[control.config.options] ?? [], {
                  transform(node) {
                    const value = node[control.config.valueKey ?? 'id'];
                    return {
                      title: node[control.config.textKey ?? 'name'],
                      value,
                      key: value
                    };
                  }
                }) : control.config.options,
                list: this.initData[control.config.list]
            } : {})
          }
        }

      });
      const filteredData = newControls.filter(item => item.config.hidden === false);
      return filteredData;
    },

    /**
     * 是否禁用
     */
    disabled() {
      if (this.action.disabled == null) {
        return false;
      }

      if (typeof this.action.disabled === 'function') {
        return this.action.disabled({
          selectedRows: this.selectedRows
        });
      }

      return this.action.disabled;
    },
  },
  watch: {
    $route(to, from) {
      // // 留服客户跳转添加注入
      if (this.action.text == '添加' && to.query.type == 'add') {
        let reg = RegExp(/^(0065|\+65)?[89]\d{7}$|^((\+|00)86)?1[3456789]\d{9}$/);
        let isPhone = reg.test(to.query.wp);
        setTimeout(() => {
          this.openDrawer();
          this.controls.map((item, index) => {
            if (item.key == 'wechat') {
              this.form[item.key] = ''
            }
            if (item.key == 'phoneNumber') {
              this.form[item.key] = ''
            }

            if (isPhone) {
              if (item.key == 'phoneNumber') {
                this.form[item.key] = to.query.wp
              }
            } else {
              if (item.key == 'wechat') {
                this.form[item.key] = to.query.wp
              }
            }
          });
        }, 0)
      }
    }
  },
  mounted() {
    // 富文本编辑上传接口
    this.action.config.controls.map((item) => {
      if (item.type === 'editor') {
        this.setting.images_upload_url = item.config.uploadUrl
      };
      if (item.defaultValue) {
        this.form[item.key] = item.defaultValue;
      }
    });

    // 留服客户跳转添加注入
    if (this.action.text == '添加' && this.$route.query.type == 'add') {
      let reg = RegExp(/^(0065|\+65)?[89]\d{7}$|^((\+|00)86)?1[3456789]\d{9}$/);
      let isPhone = reg.test(this.$route.query.wp);
      setTimeout(() => {
        this.openDrawer();
        this.controls.map((item, index) => {
          if (isPhone) {
            if (item.key == 'phoneNumber') {
              this.form[item.key] = this.$route.query.wp
            }
          } else {
            if (item.key == 'wechat') {
              this.form[item.key] = this.$route.query.wp
            }
          }
        });
      }, 0)
    }
  },

  methods: {
    disabledDate(current) {
      return current && current > dayjs().endOf('day');
    },
    handleNumChange(e, max, min) {
      let val = e.target._value
      if (val > max || val < min) {
        this.$message.error(`总分范围0~100分，请修改本次评分`);
      }
    },

    //得到嵌套对象
    getArrSpliComma(val, allItem) {
      let ckey = val.split('.')
      for (let i = 0; i < ckey.length; i++) {
        if (allItem[ckey[0]] !== null) {
          allItem = allItem[ckey[i]];
        } else {
          allItem = ''
        }
      }
      return allItem
    },
    //年月日 时分秒
    getYMDHMSMoment(value) {
      if (value) {
        return dayjs(value).format('YYYY-MM-DD HH:mm:ss')
      } else {
        return '无'
      }
    },
    handleOk() {
      this.openModal = false;
      this.openVerifyModal = false;
    },
    async blurChange(e, info) {
      let { submitUrl, key, title, blurMessage } = info
      let value = e.target._value
      try {
        let res = await this.$http.get(submitUrl, { [key]: value });
        if (res.code == '200') {
          this.openModal = true;
          this.modalTitle = title;
          let message = ''
          if (typeof blurMessage === 'function') {
            message = blurMessage(res);
          }
          this.modalContent = message;
        }
      } catch ({ message }) {
        //this.$message.error(message);
      }
    },
    getSelect(value, control) {
      if (control.limitNum && value.length > control.limitNum) {
        value.pop();
        this.$message.error(`最多只能选择${control.limitNum}个`);
      }
    },

    getSouSelect(value, control, options) {
      let that = this;
      let obj = options.find((item) => item.id === value);
      this.controls.map((item) => {
        if (item.getData) {
          if (item.key == 'handlingCountry') {
            this.form[item.key] = obj.handlingCountry;
          } else if (item.key == 'city') {
            that.form[item.key] = obj.city
          }
        };
        if (item.getAddress) {
          if (item.key == 'address') {
            this.form[item.key] = obj.address;
          } else if (item.key == 'provinceCityDistrict') {
            that.form[item.key] = obj.city
          }
        }
      })
    },

    getStep(key, options, current) {
      this.form[key] = options[current].value;
    },
    filterOption(input, option) {
      return option.name.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
    },
    filter(inputValue, path) {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.trim().toLowerCase()) > -1);
    },

    filterSouOption(input, option) {
      return option.text.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
    },

    initForm() {
      return this.action.config.controls.reduce((prev, control) => {
        if (control.type === 'dateRange') {
          const start = this.initData?.source?.[control.config.startKey] ?? control.config?.initialValue?.[0];
          const end = this.initData?.source?.[control.config.endKey] ?? control.config?.initialValue?.[1];
          prev[control.config.startKey] = start;
          prev[control.config.endKey] = end;
          prev[control.key] = [start != null ? dayjs(start) : null, end != null ? dayjs(end) : null];
        } else if (control.type === 'steps') {
          prev[control.key] = this.initData?.source?.[control.key] ?? control.config?.options[0].value;
          control.config.options.map((item, index) => {
            if (item.value == prev[control.key]) {
              this.currentStep = index;
              control.config.options = control.config.options.map((i, idx) => {
                if (idx < index) {
                  return {
                    ...i,
                    status: 'finish'
                  };
                }
                return i;
              });
            }
          })
        } else if (control.type === 'numberRange') {
          prev[control.start] = this.initData?.source?.[control.start];
          prev[control.end] = this.initData?.source?.[control.end];
        } else if (control.type === 'mSelect') {
          prev[control.key] = typeof (this.initData?.source?.[control.key]) === 'string' ? this.initData?.source?.[control.key].split(',') : this.initData?.source?.[control.key]

        }
        else {
          prev[control.key] = this.initData?.source?.[control.key] ?? control.config?.initialValue;
          if (control.type === 'switch') {
            prev[control.key] = prev[control.key] ?? false;
          } else if (control.type === 'date' || control.type === 'datetime') {
            if (typeof prev[control.key] === 'string') {
              prev[control.key] = dayjs(prev[control.key]);
            }
          }
        }
        return prev;
      }, {});
    },

    initFileUploadMap() {
      return this.action.config.controls.filter(({ type }) => type === 'file').reduce((prev, control) => {
        const path = this.initData?.source?.[control.key]?.split(',');
        let fileList = [];
        if (path) {
          path.map(item => {
            fileList.push(
              {
                name: item.split('/').pop(),
                uid: item,
                status: 'done',
                url: item,
                response: {
                  url: item
                }
              }
            )
          })
        };
        prev[control.key] = {
          previewUrl: null,
          previewVisible: false,
          fileList: fileList
        };
        return prev;
        // const path = this.initData?.source?.[control.key];
        // prev[control.key] = {
        //   previewUrl: null,
        //   previewVisible: false,
        //   fileList: path != null ? [{
        //     name: path.split('/').pop(),
        //     uid: path,
        //     status: 'done',
        //     url: path
        //   }] : []
        // };
        // return prev;
      }, {});
    },

    /**
     * 处理文件上传预览
     */
    async handleUploadPreview(file, control) {
      if (control.config.format === 'compress') {
        return;
      }
      if (!file.url && !file.preview) {
        file.preview = URL.createObjectURL(file.originFileObj);
      }

      this.fileUploadMap[control.key].previewUrl = file.url || file.preview;
      this.fileUploadMap[control.key].previewVisible = true;
    },

    /**
     * 处理文件上传状态变更
     */
    handleUploadChange(control, info) {
      if (control.config.length) {
        this.fileUploadMap[control.key].fileList = info.fileList;
        const { status, response } = info.file;
        if (status === 'done') {
          if (info.fileList.length <= control.config.length) {
            let imgArr = [];
            info.fileList.map(item => {
              imgArr.push(item.response.url)
            })
            this.form[control.key] = imgArr;
          } else {
            this.$message.error('超出上传数量')
          }

        } else if (status === 'error') {

          this.$message.error(typeof response === 'string' ? response : response.message);
        }
        if (status === 'removed' && info.fileList.length != 0) {
          let imgArr = [];
          info.fileList.map(item => {
            imgArr.push(item.response.url)
          })
          this.form[control.key] = imgArr;
        }
        if (status === 'removed' && info.fileList.length === 0) {
          this.form[control.key] = null;
        }

      } else {
        this.fileUploadMap[control.key].fileList = info.fileList;
        const { status, response } = info.file;
        if (status === 'done') {
          this.form[control.key] = response.url;
        } else if (status === 'error') {
          this.$message.error(typeof response === 'string' ? response : response.message);
        }
        if (status === 'removed' && info.fileList.length === 0) {
          this.form[control.key] = null;
        }
      }

    },

    /**
     * 限制文件上传格式与大小
     */
    onBeforeUpload(control, file) {
      const ext = file.name.split('.').pop();
      if (!control.config.accept.includes(ext)) {
        file.status = 'error';
        file.response = '文件格式有误';
        this.$message.error('文件格式有误')
        return false;
      }

      if (file.size > control.config.maxSize) {
        file.status = 'error';
        file.response = '文件大小不能超过 ' + formatBytes(control.config.maxSize);
        this.$message.error('文件大小不能超过 ' + formatBytes(control.config.maxSize))
        return false;
      }

      return true;
    },

    //--------------------------------------------
    openDrawer() {
      this.transferData = [];
      if (this.action.isSelected && (this.selectedRows.length == 0 || this.selectedRows[0] == undefined)) {
        this.$message.error('请勾选要编辑的内容');
        return
      }
      if (this.action.determine) {
        let modalObj = this.action.modalObj(this.selectedRows, this.tableData);
        if (modalObj.showMOdal) {
          this.modalTitle = modalObj.title;
          this.modalContent = modalObj.content;
          this.openModal = true;
          return
        }
      };
      getProfile()
        .then((result) => {
          if (!result.everyPermissions(this.action.permission)) {
            this.$message.error("没有权限访问");
            return;
          }
          this.loadInitData();
          this.drawerVisible = true;
        })
    },

    closeDrawer() {
      this.drawerVisible = false;
      let tdKey = 'allocationTd';
      if (this.action.config.showDialog && this.form[tdKey]) {
        this.$emit('change', this.resId);
      } else {
        this.$emit('change');
      }
      this.fileUploadMap = this.initFileUploadMap();
      this.$refs.form.resetFields();
      this.currentStep = 0;
      this.controls.filter(control => {
        if (control.type === "mSelect") {
          this.form[control.key] = [];
        };
        this.form[control.start] = '';
        this.form[control.end] = '';
      })

    },
    async loadInitData() {
      if (this.action.config.initUrl == null) {
        this.initDataReady = true;
        if (this.action.config.otherUrls && this.action.config.otherUrls.length > 0) {
          this.initLoading = true;
          for (let item of this.action.config.otherUrls) {
            let res = await this.$http.get(item.url);
            this.initData[item.nameKey] = res[item.nameKey];
            if (item.type == 'treeSelect') {
              this.initData[item.nameKey].map(i => {
                i.label = i.optionKey;
                i.value = i.optionValue;
                if (i.childrenList != '' && i.childrenList != null) {
                  i.selectable = false;
                  let arr = i.childrenList.split(',')
                  i.children = arr.map(it => ({
                    label: it,
                    value: it,
                  }))
                }
              })
            }
          }
          this.initLoading = false;
          // this.form = this.initForm();
          this.fileUploadMap = this.initFileUploadMap();
        }
        return;
      }
      this.initLoading = true;
      try {
        if (this.action.config.initOther) {
          let other = {};
          other[this.action.config.initOther] = this.selectedRows.map((item) => item[this.action.config.initOther]),
            this.initData = await get(this.action.config.initUrl, {
              id: this.selectedRows.map(({ id }) => id),
              ...other
            });
        } else {
          this.initData = await get(this.action.config.initUrl, {
            id: this.selectedRows.map(({ id }) => id)
          });
        }

        this.initDataReady = true;
        if (this.action.config.controls.findIndex(item => item.type === 'transfer') > -1) {
          const index = this.action.config.controls.findIndex(item => item.type === 'transfer');
          this.getTransferData(this.action.config.controls[index].cascadeData)
        }

        if (this.action.config.setData) {
          this.initData.source.institutionName = this.initData.institutionName.name;
        }

      } catch (e) {
        this.$message.error(e.message);
      }
      if (this.action.config.otherUrls && this.action.config.otherUrls.length > 0) {
        for (let item of this.action.config.otherUrls) {
          let res = await this.$http.get(item.url);
          this.initData[item.nameKey] = res[item.nameKey];
          if (item.type == 'treeSelect') {
            this.initData[item.nameKey].map(i => {
              i.label = i.optionKey;
              i.value = i.optionValue;
              if (i.childrenList != '' && i.childrenList != null) {
                i.selectable = false;
                let arr = i.childrenList.split(',')
                i.children = arr.map(it => ({
                  label: it,
                  value: it,
                }))
              }
            })
          }
        }
      }
      this.initLoading = false;
      this.form = this.initForm();
      this.fileUploadMap = this.initFileUploadMap();
    },

    async submit() {
      if (this.action.repeatVerify) {
        if (this.form[this.action.repeatVerify] === this.initData.source[this.action.repeatVerify]) {
          this.$message.error(this.action.verifyMsg);
          return
        }
      };
      try {
        await this.$refs.form.validate();

      } catch (e) { // 未通过表单验证
        return;
      }
      if (this.action.config.secondVerify) {
        this.openVerifyModal = true;
        return
      }
      this.submitLoading = true;
      try {
        let res = await this.$http.post(this.action.config.submitUrl, this.selectedRows.length > 0 ? {
          ...this.getForm(),
          id: this.selectedRows.map(({ id }) => id),
        } : this.getForm());
        this.$message.success(this.action.config.submitSuccessMessage ?? '操作成功');
        this.resId = res.id;
        this.closeDrawer();

      } catch ({ message }) {
        let type = message.slice(0, 6);
        if (type == 'type=1') {
          this.modalContent = message.slice(7);
          this.openModal = true;
          this.modalTitle = "提示";
        } else {
          this.$message.error(message);
        }
      }
      this.submitLoading = false;
    },

    async verifySubmit() {
      this.submitLoading = true;
      try {
        let res = await this.$http.post(this.action.config.submitUrl, this.selectedRows.length > 0 ? {
          ...this.getForm(),
          id: this.selectedRows.map(({ id }) => id),
        } : this.getForm());
        this.$message.success(this.action.config.submitSuccessMessage ?? '操作成功');
        this.resId = res.id;
        this.closeDrawer();
        this.openVerifyModal = false;
      } catch ({ message }) {
        let type = message.slice(0, 6);
        if (type == 'type=1') {
          this.modalContent = message.slice(7);
          this.openModal = true;
          this.modalTitle = "提示";
        } else {
          this.$message.error(message);
        }
      }
      this.submitLoading = false;
    },

    getPlaceholder(control) {
      if(control.placeholder) {
        return control.placeholder
      } else {
        return (['date', 'datetime', 'select', 'mSelect', 'dateRange'].includes(control.type) ? '选择' : control.type === 'file' ? '上传' : '输入') + control.label;
      }
    },

    getTreeData(control) {
      if (typeof control.config.options === 'function') {
        return control.config.options({
          initData: this.initData
        });
      }
      return control.config.options;
    },

    getMSelectData(data, control) {
      if (control.disabled) {
        let renderData = data[control.config.options];
        let haveData = data.source[control.key];
        renderData.map((item) => {
          item.disabled = haveData.indexOf(item.id) > -1;
        })
        return renderData
      }
      else if (control.dealData) {
        return control.config.options
      }
      else {
        return data[control.config.options]
      }

    },
    getStepsData(data, control) {
      if (data.source) {
        control.config.options.map((item, index) => {
          if (item.value === data.source[control.key]) {
            this.currentStep = index;
            control.config.options = control.config.options.map((i, idx) => {
              if (index < control.config.options.length - 2 && idx < index) {
                return {
                  ...i,
                  status: 'finish',
                  disabled: false
                };
              }
              if (index >= control.config.options.length - 2 && idx < index) {
                return {
                  ...i,
                  status: 'finish',
                  disabled: true
                };
              }
              if (idx >= control.config.options.length - 2) {
                return {
                  ...i,
                  disabled: true,
                  status: '',
                }
              }
              return {
                ...i,
                disabled: false,
                status: '',
              }
            });

          }
        })
      } else {
        control.config.options = control.config.options.map((item, index) => {
          if (this.currentStep < control.config.options.length - 2 && index < this.currentStep) {
            return {
              ...item,
              status: 'finish',
              disabled: false
            };
          }
          if (this.currentStep >= control.config.options.length - 2 && index < this.currentStep) {
            return {
              ...item,
              status: 'finish',
              disabled: true
            };
          }
          if (index >= control.config.options.length - 2) {
            return {
              ...item,
              disabled: true,
              status: '',
            }
          }
          return {
            ...item,
            disabled: false,
            status: '',
          }
        });
      }
      return control.config.options

    },
    handleChange(value) {
      console.log(`${value}`);
    },

    aInputNumberStep(value, status, KEY) {
      if (status.type == 'down') {
        isNaN(value) ? this.form[KEY] = - 1 : this.form[KEY] = value
      } else {
        isNaN(value) ? this.form[KEY] = 1 : this.form[KEY] = value
      }
    },

    getForm() {
      return this.action.config.controls.reduce((prev, control) => {
        if (control.type === 'numberRange') {
          prev[control.start] = this.form[control.start];
          prev[control.end] = this.form[control.end];
        }
        const value = this.form[control.key];
        if (value == null) {
          return prev;
        }
        if (control.type === 'date') {
          if (control.showNow) {
            prev[control.key] = value.format('YYYY-MM-DD HH:mm:ss');
          } else {
            prev[control.key] = value.format('YYYY-MM-DD') + (control.options && control.options.endOfDay ? ' 23:59:59' : ' 00:00:00');
          }
        } else if (control.type === 'datetime') {
          prev[control.key] = value.format('YYYY-MM-DD HH:mm:ss');
        } else if (control.type === 'dateRange') {
          prev[control.config.startKey ?? 'start' + capitalize(control.key)] = value[0]?.format('YYYY-MM-DD HH:mm:ss');
          prev[control.config.endKey ?? 'end' + capitalize(control.key)] = value[1]?.format('YYYY-MM-DD HH:mm:ss')
        } else {
          prev[control.key] = value;
        }
        return prev;
      }, {});
    },

    async getTransferData(cascadeData) {
      try {
        let { institutionsList } = await this.$http.get(cascadeData.cascadeUrl, {
          countryOfApplicationContainsIn: ''
        });
        // this.transferData = [];
        this.action.config.controls.map(item => {
          if (item.key === cascadeData.cascadeKey) {
            if (this.initData.source.list.length > 0) {
              institutionsList.map(i => {
                this.transferData.push({
                  key: i.id.toString(),
                  title: i.name,
                  disabled: this.initData.source.list.indexOf(i.id) > -1,
                })
              })
            } else {
              institutionsList.map(i => {
                this.transferData.push({
                  key: i.id.toString(),
                  title: i.name,
                  disabled: false,
                })
              })
            }
          }
        })
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    // 联级操作查询transfer数据
    async cascadeSelect(value, isCascade, cascadeData) {
      if (isCascade) {
        try {
          let { institutionsList } = await this.$http.get(cascadeData.cascadeUrl, {
            countryOfApplicationContainsIn: value
          });
          this.transferData = [];
          this.action.config.controls.map(item => {
            if (item.key === cascadeData.cascadeKey) {
              if (this.initData.source.list.length > 0) {
                institutionsList.map(i => {
                  this.transferData.push({
                    key: i.id.toString(),
                    title: i.name,
                    disabled: this.initData.source.list.indexOf(i.id) > -1,
                  })
                })
              } else {
                institutionsList.map(i => {
                  this.transferData.push({
                    key: i.id.toString(),
                    title: i.name,
                    disabled: false,
                  })
                })
              }
            }
          })
        } catch ({ message }) {
          this.$message.error(message);
        }
      }
    },
    getHideData(data, control) {
      if (control.config && typeof control.config.hidden === 'function') {
        return control.config.hidden({
          selectedRows: this.selectedRows,
          form: this.form,
          initData: this.initData,
          institutionsName: this.isSpecialInstitutions
        });
      }
      return false
    },
    radioChange(e) {
      this.action.config.controls.map(control => {
        if (control.isAssociate && typeof control.config.hidden === 'function') {
          return control.config.hidden({
            selectedRows: this.selectedRows,
            form: this.form,
            initData: this.initData,
          });
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.formCon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  .textareaCon {
    width: 100% !important;
  }

  .ant-form-item {
    width: 48%;
    margin: 10px 5px;
  }
}

.m-formCon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  .textareaCon {
    width: 100% !important;
  }

  .ant-form-item {
    width: 46%;
    margin: 10px 5px;
  }
}

.label-style {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ls-required {
  color: red;
  margin-right: 4px;
}

.ls-icon {
  margin-left: 4px;
  font-size: 14px;
  color: #999999;
}

:deep(.ant-table-body) {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px; // 横向滚动条
    height: 6px; // 纵向滚动条 必写
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 3px;
  }
}

:deep(.ant-input-number-input[disabled]) {
  color: rgba(0, 0, 0, 0.25)
}

:deep(.sortInputNumber.ant-input-number .ant-input-number-input) {
  text-align: center;
}

.sortInputNumber {
  span {
    cursor: pointer;
  }
}
</style>