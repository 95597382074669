<template>
  <div class="sk-fold-box" v-if="profile == null">
    <div class="sk-fold">
      <div class="sk-fold-cube"></div>
      <div class="sk-fold-cube"></div>
      <div class="sk-fold-cube"></div>
      <div class="sk-fold-cube"></div>
    </div>
  </div>
  <a-layout v-else>
    <a-layout-sider class="slider-bg" style="background: #1F2254" :collapsedWidth="$isMobile ? '40px' : '80px'"
      :trigger="$isMobile ? null : ''" v-model:collapsed="collapsed" collapsible>
      <div style="text-align: center;">
        <img v-if="!collapsed" style="width: 100px; margin: 24px 0;" src="../assets/img/lf-logo.png" alt="">
        <img v-if="$isMobile && collapsed" style="width: 18px; margin: 12px 0;" src="../assets/img/m-lf-logo.png"
          alt="">
        <img v-if="!$isMobile && collapsed" style="width: 20px; margin: 12px 0;" src="../assets/img/m-lf-logo.png"
          alt="">
      </div>
      <a-menu @click="onMenuSelect" style="background: #1F2254" theme="dark" mode="inline"
        :selectedKeys="defaultSelectedKeys" :openKeys="defaultOpenKeys">
        <a-menu-item key="/">
          <component :is="$icons['HomeOutlined']"></component>
          <span>首页</span>
        </a-menu-item>
        <template v-for="menu in menus">
          <a-menu-item v-if="menu.children == null" :key="menu.key">
            <component :is="$icons[menu.icon]"></component>
            <span>{{ menu.text }}</span>
          </a-menu-item>
          <a-sub-menu v-else :key="menu.key">
            <template #title>
              <span>
                <component :is="$icons[menu.icon]"></component>
                <span> {{ menu.text }}</span>
              </span>
            </template>
            <a-menu-item v-for="child in menu.children" :key="child.key">{{ child.text }}</a-menu-item>
          </a-sub-menu>
        </template>
      </a-menu>
    </a-layout-sider>

    <a-layout>
      <a-layout-header
        style="background: #fff; padding: 0;display: flex; align-items: center; justify-content: space-between;">
        <a-space>
          <component v-if="!$isMobile" class="trigger" @click="() => collapsed = !collapsed"
            :is="collapsed ? $icons['MenuUnfoldOutlined'] : $icons['MenuFoldOutlined']" />
          <a-breadcrumb>
            <a-breadcrumb-item href=""><router-link to="/">
                <home-outlined style="margin-left: 10px;" />
                首页</router-link></a-breadcrumb-item>
            <a-breadcrumb-item v-if="title !== '首页'">{{ title }}</a-breadcrumb-item>
          </a-breadcrumb>
        </a-space>

        <div style="display: flex; align-items: center;">
          <div v-if="!$isMobile" class="input-box">
            <input type="text" class="home-input" v-model="wechatOrPhoneNumberContains" placeholder="输入微信号/手机号查重">
            <SearchOutlined style="color: #666666;font-size: 18px;" @click="verifyDuplicate" />
          </div>
          <a-dropdown v-if="!$isMobile">
            <div @click.prevent style="position: relative; margin-top: 10px;">
              <BellOutlined style="font-size: 20px; padding-left: 10px; color: #666666;" />
              <span v-if="msgList.length > 0" class="red-circle"></span>
            </div>
            <template #overlay>
              <div class="msg">
                <div class="msg-header">
                  <span>未读消息</span>
                  <span style="color: #1677ff; cursor: pointer;" @click="readAllMessage">全部已读</span>
                </div>
                <div v-if="msgList.length > 0" class="msg-list">
                  <div v-for="(item, index) in msgList" class="ml-item" @click="toMessageId(item.id)">
                    <div style="display: flex; align-items: center">
                      <p class="msg-title">{{ item.title }}</p>
                      <span class="circle"></span>
                    </div>
                    <p class="msg-summary">{{ item.summary }}</p>
                    <div style="text-align: right; color: #666666">{{ getDate(item.pushTime) }}</div>
                  </div>
                </div>
                <div v-else style="text-align: center; margin: 40px 0;">
                  <img style="width: 200px;" src="../assets/img/no-msg-data.png" alt="">
                  <p style="text-align: center;">暂无未读消息哦~</p>
                </div>
                <div style="text-align: center; margin-top: 10px;">
                  <span style="color: #1677ff; cursor: pointer;" @click="toMessage()">查看全部</span>
                </div>
              </div>
            </template>
          </a-dropdown>
          <div @click="toMessage()" v-else style="position: relative; margin-top: 10px;">
            <BellOutlined style="font-size: 20px; padding-left: 10px; color: #666666;" />
            <span v-if="msgList.length > 0" class="red-circle"></span>
          </div>
          <a-dropdown>
            <a class="profile" @click.prevent>
              <a-avatar class="avatar">
                <template #icon>
                  <UserOutlined />
                </template>
              </a-avatar>
              <div class="username">{{ username }}</div>
            </a>
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item>
                  <recommendation-code />
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item>
                  <change-password />
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item key="logout">
                  <a href="javascript:">
                    <login-outlined />
                    退出登录</a>
                </a-menu-item>

              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-layout-content>
        <div class="content">
          <router-view @msgChange="msgChange" />
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
  <a-modal v-model:open="openModal" title="提示">
    <p style="padding: 20px 0;" v-show="type === 1">
      <ExclamationCircleOutlined style="color: #faad14; margin-right: 6px;" />该客资未重复，是否录入
    </p>
    <p style="padding: 20px 0;" v-show="type === 2">客户与<span style="color: #ff4d4f">{{ detail.entryPerson
        }}</span>添加的<span style="color: #ff4d4f">{{ detail.name }}</span>重复！当前客资归属人<span style="color: #ff4d4f">{{
    detail.attribution
  }}</span>，客户阶段为<span style="color: #ff4d4f">{{
      getStatus(detail.customerPhase) }}</span>，{{ detail.tips }}。<br>创建时间：<span style="color: #ff4d4f">{{
    getDate(detail.createdDate)
  }}。</span>
      <span v-if="detail.status === 'LOSS'">当前客户已流入公海，要捞起吗？</span>
    </p>
    <template v-if="type === 1" #footer>
      <a-button @click="close">取消</a-button>
      <a-button type="primary" @click="handleOk">录入</a-button>
    </template>
    <template v-if="type === 2" #footer>
      <div v-if="detail.status === 'LOSS'">
        <a-button @click="close">取消</a-button>
        <a-button type="primary" @click="pickUp">捞起客户</a-button>
      </div>
      <div v-else>
        <a-button type="primary" @click="close">确定</a-button>
      </div>
    </template>
  </a-modal>
  <a-modal v-model:open="msgModal" title="未读消息提醒">
    <p style="padding: 30px 0;">您有重要的未读消息，请前往消息中心查看</p>
    <template #footer>
      <div>
        <a-button @click="msgModal = false">下次再说</a-button>
        <a-button type="primary" @click="receive">好的</a-button>
      </div>
    </template>
  </a-modal>
</template>
<script>
import config from "../config";
import { clearProfile, getProfile } from "@/utils/session";
import ChangePassword from "@/components/system/ChangePassword";
import RecommendationCode from "@/components/system/RecommendationCode";
import dayjs from 'dayjs';
import waterMark from '@/utils/waterMark.js';
export default {
  components: { ChangePassword, RecommendationCode },
  data() {
    return {
      collapsed: false,
      profile: null,
      wechatOrPhoneNumberContains: '',
      openModal: false,
      type: 0,
      detail: {},
      msgList: [],
      msgModal: false,
      hiddenModel: false,
    }
  },
  computed: {
    username() {
      if (this.profile == null) {
        return '...';
      }
      return this.profile.username;
    },

    defaultSelectedKeys() {
      return [this.$route.path];
    },

    breadcrumbs() {
      const breadcrumbs = this.$route.meta.breadcrumbs;
      if (breadcrumbs == null) {
        return [];
      }
      return [
        ...breadcrumbs,
        this.$route.meta.title
      ];
    },

    title() {
      return this.$route.meta.title;
    },

    defaultOpenKeys() {
      return this.menus
        .filter(menu => menu.defaultOpen || (menu.children && menu.children.some(child => child.key === this.$route.path)))
        .map(menu => menu.key);
    },

    menus() {
      if (this.profile == null) {
        return [];
      }
      return config.menus.map(menu => ({
        key: menu.key,
        defaultOpen: menu.defaultOpen ?? true,
        text: menu.text,
        icon: menu.icon ?? 'setting',
        children: menu.pages
          .map(pageKey => config.pages[pageKey])
          .filter(page => this.profile.everyPermissions(page.permission))
          .map(page => ({
            key: page.key,
            text: page?.title ?? page.key
          }))
      })).filter(({ children }) => children.length > 0);

      
    }
  },

  beforeMount() {
    if (this.$isMobile) {
      this.collapsed = true
    }
  },

  async mounted() {
    try {
      this.profile = await getProfile();
      this.notificationList();
    } catch ({ message, status }) {
      if (status !== 401) { // 不是未登录错误
        this.$message.error(message);
      }
    }
  },

  methods: {
    async onMenuSelect({ key }) {
      await this.$router.push(key);
    },

    async handleMenuClick({ key }) {
      if (key === 'logout') {
        await this.logout();
        waterMark.set('')
      }
    },

    //获取部门数据
    async verifyDuplicate() {
      if (!this.wechatOrPhoneNumberContains) {
        this.$message.error('请输入微信号/手机号');
        return
      }
      try {
        let res = await this.$http.get('/verifyDuplicate', {
          wechatOrPhoneNumberIs: this.wechatOrPhoneNumberContains.trim()
        });
        if (res.code == '200') {
          this.detail = res;
          this.type = 2;
          this.openModal = true;
        } else if (res.code == '201') {
          this.type = 1;
          this.openModal = true;
        }
      } catch ({ message }) {

      }
    },

    // 捞起
    async pickUp() {
      try {
        let res = await this.$http.post('/callingUpTheCustomer', {
          id: this.detail.id
        });
        if (res.code == '200') {
          this.$message.success('捞起成功');
          this.openModal = false;
        } else {
          this.$message.error('捞起失败');
        }
      } catch ({ message }) {
      }
    },

    handleOk() {
      this.$router.push({
        path: '/newCustomer/institutionsCustomer',
        query: {
          type: 'add',
          wp: this.wechatOrPhoneNumberContains
        }
      })
      this.wechatOrPhoneNumberContains = '';
      this.openModal = false;
    },

    close() {
      this.wechatOrPhoneNumberContains = '';
      this.openModal = false;
    },

    getStatus(val) {
      switch (val) {
        case 'NEW_CUSTOMER_CAPITAL':
          return '新客咨';
        case 'CONTACTS':
          return '已联系上';
        case 'IN_INTENTION':
          return '确定意向中';
        case 'TO_BE_ALLOCATED':
          return '待分配';
        case 'ALLOCATED':
          return '已分配';
        case 'MULTIPLE_ASSIGNMENT':
          return '多次分配';
        default:
          break
      }
    },

    async notificationList() {
      try {
        let res = await this.$http.get('/message/employee/list', {
          page: 1,
          size: 3,
          readStatus: 0
        })
        if (res.code == '200') {
          this.msgList = res.data;
          if (this.msgList.length > 0 && this.hiddenModel == false) {
            this.msgModal = true;
          }
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async readAllMessage() {
      try {
        let res = await this.$http.get('/message/employee/readAllMessage')
        if (res.code == '200') {
          this.notificationList();
          this.$message.success('操作成功');
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    msgChange(e) {
      if (e) {
        this.hiddenModel = true;
        this.notificationList();
      }
    },

    receive() {
      this.$router.push({
        path: '/message',
      })
      this.msgModal = false;
    },

    getDate(val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm:ss');
    },

    async toMessageId(id) {
      let r = await this.$http.get('/auth/profile');
      this.profile.notReadNumber = r.notReadNumber;
      this.notificationList();
      this.$router.push({
        path: '/message',
        query: {
          id: id
        }
      });
    },

    toMessage() {
      this.$router.push({
        path: '/message',
      })
    },

    async logout() {
      const hideMessage = this.$message.loading('正在退出...', 0);
      try {
        await this.$http.post('/auth/logout');
        this.$message.success('已退出登录');

        clearProfile();
        await this.$router.replace('/login');
      } catch ({ message }) {
        this.$message.error(message);
      }
      hideMessage();
    },
  }
};
</script>
<style lang="scss" scoped>
.ant-layout {
  min-height: 100vh;
  background: #f5f5f5;
}

.input-box {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.home-input {
  background: none;
  outline: none;
  border: 1px solid #eeeeee;
  width: 240px;
  padding: 8px 20px;
  border-radius: 8px;
  margin-right: 10px;
}

.ant-layout-header {
  box-shadow: 0 0 12px rgba(#000, .15);

  h1 {
    margin: 0;
    line-height: 1.5;
    position: relative;
    top: -2px;
  }

  .profile {
    display: flex;
    align-items: center;
    float: right;
    padding: 0 24px 0 12px;

    .avatar {
      background: #1F94F7;
    }

    .username {
      margin-left: 8px;
      color: #333;
    }

    &:hover {
      background: #f5f5f5;
      cursor: pointer;
    }
  }
}

.content {
  margin: 12px 16px;
}

.breadcrumb {
  margin: 12px 16px;
}

.trigger {
  font-size: 18px;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  background: #f5f5f5;
}

:deep(.ant-menu-submenu-selected >.ant-menu-submenu-title) {
  background-color: #5051F9 !important;
}

:deep(.ant-menu-submenu>.ant-menu) {
  background-color: #1F2254 !important;
}

.red-circle {
  position: absolute;
  top: 18px;
  right: 0px;
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
}

.msg {
  background-color: #ffffff;
  width: 300px;
  padding: 10px 0;
  border-radius: 8px;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  overflow-y: scroll;

  .msg-header {
    padding: 0 16px 10px 16px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .msg-list {
    padding: 0 10px;
  }

  .ml-item {
    padding: 10px 0;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;
  }

  .msg-title {
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .circle {
    right: 8px;
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
    margin-left: 10px;
    flex-shrink: 0;
  }

  .msg-summary {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    /* 超出几行省略 */
    overflow: hidden;
    padding: 6px 0;
    color: #666666;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: none;
  /*可以设置轨道颜色*/
  padding-right: 2px;
}

/*滚动条*/
::-webkit-scrollbar-thumb {
  background: rgba(142, 142, 142, 0.4);
  border-radius: 6px;
}
</style>
<style lang="scss">
.slider-bg {
  .ant-layout-sider-trigger {
    background: #1F2254;
  }
}

.mobile-menu {
  .ant-menu-item-selected {
    background-color: #1F2254 !important;
    color: #5051F9;
  }

  .ant-menu-inline {
    background: #1F2254 !important;
  }
}

.ant-menu-item-selected {
  background-color: #1F2254 !important;
  color: #5051F9 !important;
}
</style>