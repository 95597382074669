import { reasonCancel, orderCancel } from '@/utils/public';
export default {
  key: '/order/cancelorder',
  title: '取消的订单',
  type: 'table',
  permission: '/order/institutionsCancelledOrders/list',
  config: {
    dataUrl: '/order/institutionsCancelledOrders/list',
    filter: {
      initUrl: "/order/institutionsCancelledOrders/list/get",
      controls: [
        {
          key: 'institutionsIdIs',
          label: '签单机构',
          type: 'souSelect',
          config: {
            options: "institutionsList"
          }
        },
        {
          key: 'activateIdIs',
          label: '业务归属人',
          type: 'souSelect',
          width: '260',
          config: {
            options: "employeesList"
          }
        },
        {
          key: 'staffPersonIdIs',
          label: '客服',
          type: 'select',
          width: '260',
          config: {
            options: "employeesList"
          }
        },
        {
          key: 'cancelTime',
          label: '取消时间',
          type: 'dateRange'
        },
        {
          key: 'nameContains',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'attributablePersonIdIs',
          label: '客资归属人',
          type: 'souSelect',
          width: '260',
          config: {
            options: "employeesList"
          }
        },
        {
          key: 'reasonForCancellationIs',
          label: '退款原因',
          type: 'select',
          config: {
            options: reasonCancel
          }
        },
        {
          key: 'paymentStatusIs',
          label: '退款状态',
          type: 'select',
          config: {
            options: orderCancel
          }
        },
        {}
      ]
    },

    /* 表格操作 */
    actions: [
    ],

    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['signingTime'],
      /* 默认排序 */
      defaultSort: '-signingTime'
    },
    columns: [
      {
        key: 'orderNumber',
        title: '订单号',
        width: '130px'
      },
      {
        key: 'institutions.name',
        width: '130px',
        title: '签单机构',
      },
      {
        key: 'activate.name',
        width: '120px',
        title: '业务归属人'
      },
      {
        key: 'staffPerson.name',
        title: '客服',
        width: 100,
      },
      {
        key: 'signingTime',
        width: '130px',
        title: '签单时间',
        type: 'datetime'
      },
      {
        key: 'cancelTime',
        width: '130px',
        title: '取消时间',
        type: 'datetime'
      },
      {
        key: 'institutionsCustomerRelationship.name',
        width: '130px',
        title: '客户姓名',
      },
      {
        key: 'attributablePerson.name',
        width: '120px',
        title: '客资归属人'
      },
      {
        key: 'serviceItem.content',
        width: '130px',
        title: '服务项目',
      },
      {
        key: 'orderAmount',
        width: '130px',
        title: '订单金额',
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'amountPaid',
        width: '130px',
        title: '已付金额',
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'amountToBeRefunded',
        width: '130px',
        title: '应退金额(元)',
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'refundedAmount',
        width: '130px',
        title: '已退金额(元)',
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'paymentStatus',
        width: '130px',
        title: '退款状态',
        type: 'enum',
        config: {
          constants: {
            REFUND_IN_PROCESS: {
              text: '退款中',
              color: 'red'
            },
            REFUND_COMPLETE: {
              text: '退款完成',
              color: 'gray'
            },
          }
        }
      },
      {
        key: 'reasonForCancellation',
        width: '130px',
        title: '取消原因',
      },
      {
        key: 'followUp.name',
        width: '130px',
        title: '订单跟进人',
      },
      {
        key: 'attributable.name',
        width: '130px',
        title: '订单归属人',
      },
      {
        key: 'orderStatus',
        width: '230px',
        title: '订单情况',
        ellipsis: true
      },
      {
        key: 'actions',
        title: '操作',
        type: 'actions',
        width: '140px',
        fixed: 'right'
      },
    ],

    /* 表格行操作 */
    rowActions: [
      {
        key: 'detail',
        text: '订单详情',
        type: 'blank',
        permission: '/order/institutionsCancelledOrders/details',
        config: {
          title: '订单详情',
          component: () => import("./views/cancelOrderDetail")
        }
      },
      {
        key: 'add',
        text: '退款',
        icon: 'plus',
        type: 'form',
        permission: '/order/institutionsCancelledOrders/refund',
        disabled: ({ selectedRows }) => {
          return selectedRows[0].paymentStatus === "REFUND_COMPLETE";
        },
        config: {
          color: 'primary',
          title: '退款',
          initUrl: '/order/institutionsCancelledOrders/refund/get',
          submitUrl: '/order/institutionsCancelledOrders/refund',
          submitText: '确认',
          controls: [
            {
              key: 'orgName',
              label: '签单机构',
              type: 'text',
              disabled: true,
            },
            {
              key: 'name',
              label: '客户姓名',
              type: 'text',
              disabled: true,
            },
            {
              key: 'serviceItemId',
              label: '服务项目',
              isDisabled: (rows, form)=>{
                return true
              },
              type: 'select',
              config: {
                textKey: 'content',
                options: "signingItemList",
              }
            },
            {
              key: 'orderAmount',
              label: '订单金额',
              type: 'numeric',
              config: {
                format: 'currency'
              },
              disabled: true,
            },
            {
              key: 'amountPaid',

              label: '已付金额',
              type: 'numeric',
              config: {
                format: 'currency'
              },
              disabled: true,
            },
            {
              key: 'amountToBeRefunded',
              label: '应退全额',
              type: 'numeric',
              config: {
                format: 'currency'
              },
              disabled: true,
            },
            {
              key: 'refundedAmount',
              label: '已退金额',
              type: 'numeric',
              config: {
                format: 'currency'
              },
              disabled: true,
            },
            {
              key: 'amount',
              label: '本次退款',
              type: 'numeric',
              required: true,
              config: {
                format: 'currency',
                max: (form) => {
                  return form.amountToBeRefunded - form.refundedAmount
                }
              }
            },
            {
              key: 'time',
              label: '退款日期',
              type: 'date',
              required: true,
              showNow: true,
              disabledDate: true,
            },
            {}
          ]
        }
      },
    ]
  }
};
