import userIntention from "./userIntention";
import userList from "./userList";
import platformData from "./platformData";
import storeData from "./storeData";
import burialPoint from "./burialPoint";
export default [
  userIntention,
  userList,
  platformData,
  storeData,
  burialPoint
]
