import { reasonCancel, intentionLevel, serviceItems, orderInProgress, rebateStatus, rebateState, customerSource } from '@/utils/public';
import * as http from '../../utils/http';
export default {
  key: '/order/institutionsOrder',
  title: '进行的订单',
  type: 'table',
  permission: '/order/institutionsOrder/list',
  config: {
    dataUrl: '/order/institutionsOrder/list',
    filter: {
      initUrl: "/order/institutionsOrder/list/get",
      otherUrls: [
        {
          url: '/option/config/getByOptionName?optionName=客户来源',
          nameKey: 'data',
          type: 'treeSelect'
        }
      ],
      controls: [
        {
          key: 'orderNumber',
          label: '订单号',
          type: 'text'
        },
        {
          key: 'institutionsIdIs',
          label: '签单机构',
          type: 'souSelect',
          config: {
            options: "institutionsList"
          }
        },
        {
          key: 'activateIdIs',
          label: '业务归属人',
          type: 'souSelect',
          width: '260',
          config: {
            options: "employeesList"
          }
        },
        {
          key: 'staffPersonIdIs',
          label: '客服',
          type: 'select',
          width: '260',
          config: {
            options: "employeesList"
          }
        },
        {
          key: 'signingTime',
          label: '签单时间',
          type: 'dateRange',

        },
        {
          key: 'nameContains',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'customerSourceIs',
          label: '客户来源',
          type: 'treeSelect',
          config: {
            list: 'data',
          }
        },
        {
          key: 'attributablePersonIdIs',
          label: '客资归属人',
          type: 'souSelect',
          width: '260',
          config: {
            options: "employeesList"
          }
        },
        {
          key: 'paymentStatusIs',
          label: '支付状态',
          type: 'select',
          config: {
            options: orderInProgress
          }
        },
        {
          key: 'paymentCompletionTime',
          label: '付款完成时间',
          type: 'dateRange',
        },
        {
          key: 'rebateTypeIs',
          label: '返佣类型',
          type: 'select',
          config: {
            options: rebateStatus
          }
        },
        {
          key: 'rebateStatusIn',
          label: '返佣状态',
          type: 'mSelect',
          config: {
            options: rebateState
          }
        },
        {
          key: 'rebateCompleteTime',
          label: '返佣完成时间',
          type: 'dateRange',
        },
        {
          key: 'excludeInstitutionsId',
          label: '排除机构',
          type: 'mSelect',
          config: {
            options: "institutionsList"
          }
        },
      ]
    },

    /* 表格操作 */
    actions: [
      {
        key: 'chapter',
        text: '导出数据',
        type: 'url',
        permission: '/order/institutionsOrder/export',
        config: {
          title: '导出数据',
          color: 'primary',
        },
        options: {
          url: '/api/order/institutionsOrder/export',
          params: true,
          newPage: true
        }
      },
    ],

    columns: [
      {
        key: 'orderNumber',
        title: '订单号',
        width: '130px',
      },
      {
        key: 'institutions.name',
        width: '150px',
        title: '签单机构',
      },
      {
        key: 'rebatesRules.rebateType',
        width: '100px',
        title: '返佣类型',
        type: 'enum',
        config: {
          constants: {
            PROPORTIONAL_REBATES: {
              text: '比例返佣',
              color: 'orange'
            },
            FIXED_REBATES: {
              text: '固定返佣',
              color: 'green'
            }
          }
        }
      },
      {
        key: 'rebatesRules.amountToBeRefunded',
        width: '150px',
        title: '应返金额',
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'rebatesRules.returnedAmount',
        width: '150px',
        title: '已返金额',
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'rebatesRules.rebateStatus',
        width: '100px',
        title: '返佣状态',
        type: 'enum',
        config: {
          constants: {
            PENDING_REBATES: {
              text: '待返佣',
              color: 'red'
            },
            REBATES_ARE_ONGOING: {
              text: '返佣中',
              color: 'orange'
            },
            THE_REBATE_IS_COMPLETE: {
              text: '返佣完成',
              color: 'green'
            }
          }
        }
      },
      {
        key: 'activate.name',
        width: '120px',
        title: '业务归属人'
      },
      {
        key: 'staffPerson.name',
        title: '客服',
        width: 100,
      },
      {
        key: 'signingTime',
        title: '签单时间',
        type: 'datetime',
        width: '100px',
      },
      {
        key: 'institutionsCustomerRelationship.name',
        width: '100px',
        title: '客户姓名',
      },
      {
        key: 'institutionsCustomer.customerSource',
        width: '100px',
        title: '客户来源',
      },
      {
        key: 'attributablePerson.name',
        width: '120px',
        title: '客资归属人'
      },
      {
        key: 'serviceItem.content',
        width: '100px',
        title: '服务项目',
      },
      {
        key: 'orderAmount',
        title: '订单金额',
        width: '130px',
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'amountPaid',
        title: '已付金额',
        width: '130px',
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'paymentStatus',
        title: '支付状态',
        type: 'enum',
        width: '90px',
        align: 'center',
        config: {
          constants: {
            TO_BE_PAID: {
              text: '待支付',
              color: 'orange'
            },
            IN_PAYMENT: {
              text: '支付中',
              color: 'green'
            },
            PAYMENT_COMPLETED: {
              text: '支付完成',
              color: 'cyan'
            },
            REFUND_IN_PROCESS: {
              text: '退款中',
              color: 'red'
            },
            REFUND_COMPLETE: {
              text: '退款完成',
              color: 'gray'
            },
          }
        }
      },
      {
        key: 'paymentCompletionTime',
        title: '付款完成时间',
        type: 'datetime',
        width: '110px',
      },
      {
        key: 'attributable.name',
        width: '100px',
        title: '订单归属人',
      },
      {
        key: 'followUp.name',
        width: '100px',
        title: '订单跟进人',
      },
      {
        key: 'orderStatus',
        title: '订单情况',
        width: '100px',
        ellipsis: true
      },
      {
        key: 'name',
        title: '操作',
        fixed: 'right',
        type: 'actions',
        width: document.documentElement.clientWidth <= 768 ? '100px' : '240px'
      }
    ],

    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['signingTime'],
      /* 默认排序 */
      defaultSort: '-signingTime'
    },

    /* 表格行操作 */
    rowActions: [
      {
        key: 'detail',
        text: '客户详情',
        type: 'blank',
        permission: '/order/institutionsOrder/customerDetails',
        config: {
          title: '客户详情',
          component: () => import("./views/institutionsCustomerDetail")
        }
      },
      {
        key: 'detail',
        text: '订单详情',
        type: 'blank',
        permission: '/order/institutionsOrder/details',
        config: {
          title: '订单详情',
          component: () => import("./views/orderDetail")
        }
      },
      {
        key: 'detail',
        text: '返佣规则',
        type: 'blank',
        // disabled: ({ selectedRows }) => {
        //   return selectedRows[0].rebatesRules.rebateType != null;
        // },
        subText: (rows) => {
          if (rows[0].rebatesRules.auditStatus == 'RETURNED') {
            return '返佣修改'
          } else {
            return '返佣规则'
          }
        },
        permission: '/order/institutionsOrder/rebatesRules',
        config: {
          title: '返佣规则',
          component: () => import("./views/rebateRulesDetail")
        }
      },
      {
        key: 'add',
        text: '返佣',
        icon: 'add',
        type: 'form',
        disabled: ({ selectedRows }) => {
          return selectedRows[0].rebatesRules.rebateType == null || selectedRows[0].rebatesRules.rebateStatus == 'THE_REBATE_IS_COMPLETE' || selectedRows[0].rebatesRules.auditStatus == 'RETURNED';
        },
        permission: '/order/institutionsOrder/rebates',
        config: {
          color: 'primary',
          title: '本次返佣',
          submitUrl: "/order/institutionsOrder/rebates",
          submitText: '确认',
          submitSuccessMessage: '提交成功，审核通过后生效',
          controls: [
            {
              key: 'returnCommissionAmount',
              label: '返佣金额',
              required: true,
              type: 'numeric',
              config: {
                format: "money",
                suffix: '元'
              }
            },
            {
              key: 'rebateTime',
              label: '返佣时间',
              required: true,
              showNow: true,
              type: 'date',
              disabledDate: true,
            },
            {
              key: 'paymentVoucher',
              label: '支付凭证',
              required: true,
              tips: "支付凭证的时间和金额要与返佣金额和时间保持一致",
              type: 'file',
              config: {
                uploadUrl: '/api/order/institutionsOrder/addUpload',
                format: "image",
                accept: ["jpeg", "png", 'jpg']
              }
            },
            {}
          ]
        }
      },
      {
        key: 'add',
        text: '跟进',
        icon: 'add',
        type: 'form',
        permission: '/order/institutionsOrder/followUpWithCustomers',
        disabled: ({ selectedRows }) => {
          if(!selectedRows[0].institutionsCustomer || !selectedRows[0].institutionsCustomer.customerSource)
          return true
        },
        config: {
          color: 'primary',
          title: '新增跟进记录',
          initUrl: "/order/institutionsOrder/edit/get",
          submitUrl: "/order/institutionsOrder/followUpWithCustomers",
          submitText: '确认添加',
          controls: [
            {
              key: 'name',
              label: '客户姓名',
              type: 'text',
              required: true,
              disabled: true,
              config: {
                rules: [
                  { min: 1, max: 30, message: '只能输入1-30个字符' }
                ]
              }
            },
            {
              key: 'followUpTime',
              label: '跟进时间',
              required: true,
              showNow: true,
              type: 'date',
            },
            {
              key: 'intentionLevel',
              label: '意向等级',
              required: true,
              type: 'select',
              config: {
                options: intentionLevel
              }
            },
            {
              key: 'followUpContent',
              label: '跟进内容',
              required: true,
              type: 'textarea',
              config: {
                rules: [
                  { min: 1, max: 500, message: '只能输入1-500个字符' }
                ]
              }
            },
          ]
        }
      },
      {
        key: 'add',
        text: '取消订单',
        icon: 'plus',
        type: 'form',
        permission: '/order/institutionsOrder/cancelOrder',
        config: {
          color: 'primary',
          title: '取消订单',
          initUrl: '/order/institutionsOrder/cancelOrder/get',
          submitUrl: '/order/institutionsOrder/cancelOrder',
          submitText: '确认',
          controls: [
            {
              key: 'orgName',
              label: '签单机构',
              type: 'text',
              disabled: true,
            },
            {
              key: 'name',
              label: '客户姓名',
              type: 'text',
              disabled: true,
            },
            {
              key: 'serviceItemId',
              label: '服务项目',
              isDisabled: (rows, form) => {
                return true
              },
              type: 'select',
              config: {
                textKey: 'content',
                options: "signingItemList",
              }
            },
            {
              key: 'orderAmount',
              label: '订单金额',
              type: 'numeric',
              config: {
                format: 'currency'
              },
              disabled: true,
            },
            {
              key: 'amountPaid',
              label: '已付金额',
              type: 'numeric',
              config: {
                format: 'currency'
              },
              disabled: true,
            },
            {
              key: 'reasonForCancellation',
              label: '取消原因',
              type: 'select',
              required: true,
              config: {
                options: reasonCancel
              }
            },
            {
              key: 'amountToBeRefunded',
              label: '应退金额',
              type: 'numeric',
              required: true,
              tips: '需要退款的订单,在取消的订单列表中操作退款',
              config: {
                format: 'currency',
                max: (form) => {
                  return form.amountPaid
                }
              }
            },
            {
              key: 'cancelTime',
              label: '取消时间',
              type: 'date',
              showNow: true,
              disabledDate: true,
              required: true,
            },
            {
              key: 'orderStatus',
              label: '订单情况',
              type: 'textarea',
            },
          ]
        }
      },
    ]
  }
};
