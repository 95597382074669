export default {
  key: '/system/sortingRules',
  title: '排序规则',
  permission: '/sortrule/operation/list',
  config: {
    component: () => import("./views/sortingRulesDetail"),
    actions: [
      {
        text: '机构门店列表',
        permission: '/sortrule/operation/list',
      },
      {
        text: '服务项目列表',
        permission: '/sortrule/operation/serviceProject/list',
      },
      {
        text: '录取案例列表',
        permission: '/sortrule/operation/case/list',
      },
      {
        text: '权重设置-门店信息',
        permission: '/sortrule/weight/edit',
      },
      {
        text: '权重设置-服务项目',
        permission: '/sortrule/weight/serviceProject/edit',
      },
      {
        text: '权重设置-录取案例',
        permission: '/sortrule/weight/case/edit',
      }
    ],
    rowActions: [
      {
        text: '编辑运营系数-机构门店',
        permission: '/sortrule/operation/edit',
      },
      {
        text: '编辑运营系数-服务项目',
        permission: '/sortrule/operation/serviceProject/edit',
      },
      {
        text: '编辑运营系数-录取案例',
        permission: '/sortrule/operation/case/edit',
      }
    ]
  }
}