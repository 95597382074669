import { pagePosition, eventType, port, exportDate } from '@/utils/public';
export default {
  key: '/registeredUsers/burialPoint',
  title: '埋点统计',
  type: 'table',
  permission: '/event/tracking/statistics/list',
  config: {
    dataUrl: '/event/tracking/statistics/list',
    summary: true,
    sticky: true,
    setPageSize: 100,
    filter: {
      // initUrl: '/event/tracking/statistics/list',
      controls: [
        {
          key: 'time',
          label: '时间',
          type: 'dateRange',
          resetDisabled: true,
          config: {
            initialValue: '当月',
          }
        },
        {
          key: 'name',
          label: '事件名称',
          type: 'text'
        },
        {
          key: 'location',
          label: '页面位置',
          type: 'souSelect',
          config: {
            options: pagePosition
          }
        },
        {
          key: 'eventType',
          label: '事件类型',
          type: 'select',
          config: {
            options: eventType
          }
        },
        {
          key: 'platform',
          label: '端口',
          type: 'select',
          config: {
            options: port
          }
        },
        {
          key: 'exportParam',
          label: '导出日期环比',
          type: 'select',
          config: {
            options: exportDate
          }
        },
      ]
    },

    /* 表格操作 */
    actions: [
      {
        key: 'chapter',
        text: '导出为Excel',
        type: 'url',
        permission: '/event/tracking/statistics/export',
        config: {
          title: '导出为Excel',
          color: 'primary',
        },
        options: {
          url: '/api/event/tracking/statistics/export',
          params: true,
          newPage: true
        }
      },
    ],

    columns: [
      {
        key: 'name',
        width: '150px',
        title: '事件名称',
      },
      {
        key: 'param',
        width: '150px',
        title: '参数',
      },
      {
        key: 'location',
        width: '100px',
        title: '页面位置',
      },
      {
        key: 'eventType',
        title: '事件类型',
        width: '100px',
      },
      {
        key: 'totalNumber',
        title: '触发次数',
        width: '100px',
        summary: true,
      },
      {
        key: 'totalUserNumber',
        title: '触发用户数',
        width: '100px',
        summary: true,
      },
      {
        key: 'platform',
        title: '端口',
        width: '100px',
      },
      // {
      //   key: 'platform',
      //   title: '上报平台',
      //   width: '100px',
      // },
    ],

    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: [''],
      /* 默认排序 */
      defaultSort: ''
    },

    /* 表格行操作 */
    rowActions: [

    ]
  }
};
